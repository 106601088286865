html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: rgba(0, 0, 0, 0.54);
}

td {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  max-width: 100px;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appbar {
  flex: none;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  position: relative;
  box-sizing: border-box;
  z-index: 1;
}

.left {
  z-index: 1;
  /*margin-right: 8px;*/
}

.center {
  background: rgb(238, 238, 238);
  /*margin: 0 8px;*/
  padding: 0 16px;
}

.right {
  z-index: 1;
  /*margin-left: 8px;*/
}

.toolbar {
  flex: none;
  min-height: 0;
  z-index: 1;
}

.content {
  flex: 1;
  overflow: auto;
  position: relative;
}

.Axis path,
.Axis line {
  stroke: #E0E0E0;
}

.Axis text {
  font-size: 11px;
  fill: black;
}
